<template>
  <v-container fluid class="kanban">
    <v-chip-group
      active-class="primary--text"
      class="mb-5 pb-3"
      column
      v-model="template"
    >
      <v-chip
        outlined
        v-for="template in templates"
        :key="template.pk"
        :value="template.pk"
      >
        {{
          `${template.template !== undefined ? template.template + ' - ' : ''}${
            template.name
          }`
        }}
      </v-chip>
    </v-chip-group>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
        v-for="x in [1, 2, 3, 4, 6, 7, 8, 9]"
        v-show="loadStatus"
        :key="'skeleton-' + x"
      >
        <v-skeleton-loader type="article" class="mx-auto"></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="statusContainer">
      <v-row>
        <v-col
          class="pt-1"
          cols="12"
          v-for="(main_status, key) in main_statuses"
          :key="`${'status'}-${key}`"
        >
          <status
            :filter="main_status.pk"
            :preStatuses="statuses"
            :template="template"
          ></status>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import status from './status.vue'
export default {
  components: {
    status
  },
  data() {
    return {
      templates: [],
      load: false,
      loading: false,
      loadStatus: false,
      template: null,
      statuses: [],
      main_statuses: [],
      newStatus: '',
      oldStatus: '',
      product: ''
    }
  },
  mounted() {
    this.getTemplates()
  },
  watch: {
    template: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrderStatus()
          this.getMainOrderStatus()
        } else {
          this.statuses = []
        }
      }
    }
  },
  created() {
    const service = this.$dragula.$service
    service.eventBus.$on('drop', (block) => {
      this.product = Number(block.el.dataset.key)
      this.newStatus = Number(block.container.dataset.status)
      if (this.$refs['getbodyemail'] != undefined) {
        this.$refs['getbodyemail'].getBodyEmail()
        for (let status of this.statuses) {
          let product = status.products.find(
            (item) => item.code == this.email['code']
          )
          if (product != undefined) {
            this.oldStatus = status.name
          }
        }
      }
      if (this.statuses[0] != undefined) {
        let order = this.statuses.filter((item) => item.pk == this.newStatus)
        let name =
          order != undefined && order[0].root == 1
            ? order[0].name.split(' - ')[1]
            : null
        this.form = {
          product: this.product,
          preprocess: name == 'approved' ? 'in_process' : name
        }
        this.$api.dashboard.edit({ pk: this.newStatus, form: this.form })
      }
    })
  },
  methods: {
    async getOrderStatus() {
      try {
        this.loadStatus = true
        let res = await this.$api.dashboard.list({
          pk: this.template,
          opt: {
            params: {
              user: this.isAdmin ? null : this.me.id
            }
          }
        })
        this.statuses = res.data
      } finally {
        this.loadStatus = false
      }
    },
    async getMainOrderStatus() {
      try {
        this.load = true
        let response = await this.$api.sale.status.list({
          opt: {
            params: {
              template: this.template
            }
          }
        })
        this.main_statuses = response.data
      } finally {
        this.load = false
      }
    },
    async getTemplates() {
      try {
        this.loading = true
        let response = await this.$api.product.template.list({
          opt: {
            params: {
              order: true,
              company: this.company.pk
            }
          }
        })
        this.templates = response.data.filter((item) => item.orderNumber > 0)
        this.template = this.templates.length > 0 ? this.templates[0].pk : null
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'session/isAdmin',
      me: 'session/me',
      company: 'company/getCompanyData'
    })
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="viewFilters ? 10 : 12">
        <v-card style="margin-bottom: 60px">
          <v-toolbar dark color="secondary">
            <v-toolbar-title
              v-if="$vuetify.breakpoint.smAndUp"
              class="font-weight-black"
              >{{ $tc('calendar', 2) }} -
              {{ $tc(`month_list.${currentMonth}`) }}</v-toolbar-title
            >
            <v-spacer />
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              @click="$refs.calendar.prev()"
            >
              <v-icon>fa-arrow-left</v-icon>
            </v-btn>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              @click="$refs.calendar.next()"
            >
              <v-icon>fa-arrow-right</v-icon>
            </v-btn>
            <i-btn
              v-if="!viewFilters"
              :title="$t('filter')"
              outlined
              classes="mx-1"
              icon="fas fa-ellipsis-v"
              @click="openFilters"
            />
          </v-toolbar>
          <v-row>
            <v-col cols="12" xs="12">
              <v-sheet height="700">
                <v-row>
                  <v-col cols="6" sm="3" xs="6">
                    <v-select
                      v-model="type"
                      :items="types"
                      item-text="label"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="ma-2"
                      :label="$t('type')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" xs="12">
                    <v-switch
                      v-model="filter_type"
                      :label="$tc('equipment_maintenance', true)"
                      :color="notificationsColor.maintenance"
                      value="maintenance"
                      @change="filter"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="3" xs="12">
                    <v-switch
                      v-model="filter_type"
                      :label="$tc('expense', true)"
                      :color="notificationsColor.expense"
                      value="expense"
                      @change="filter"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="3" xs="12">
                    <v-switch
                      v-model="filter_type"
                      :label="$tc('order', true)"
                      :color="notificationsColor.orderproduct"
                      value="order"
                      @change="filter"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>
                <br />
                <v-calendar
                  ref="calendar"
                  v-model="value"
                  color="primary"
                  :type="type"
                  :events="events"
                  :event-color="getEventColor"
                  :event-ripple="false"
                  @change="getEvents"
                  @mousedown:event="startDrag"
                  @mousedown:day="startTime"
                  @mousemove:day="mouseMove"
                  @mouseup:day="endDrag"
                  @mouseleave.native="cancelDrag"
                >
                  <template v-slot:event="{ event, timed, eventSummary }">
                    <div
                      class="v-event-draggable"
                      :style="{
                        color:
                          event.statusDark || event.paid ? 'white' : 'black'
                      }"
                    >
                      <component :is="{ render: eventSummary }"></component>
                    </div>
                    <div v-if="!timed"></div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  :position-x="positionX"
                  :position-y="positionY"
                >
                  <v-card
                    color="grey lighten-4"
                    :min-width="`${cardWidth}px`"
                    :max-width="`${cardHeight}px`"
                  >
                    <v-toolbar
                      :style="{
                        color:
                          selectedEvent.statusDark || selectedEvent.paid
                            ? 'white'
                            : 'black'
                      }"
                      :color="
                        selectedEvent.model === 'order' ||
                        selectedEvent.model === 'orderproduct'
                          ? selectedEvent.statusColor
                          : selectedEvent.background
                      "
                      dark
                    >
                      <v-toolbar-title
                        class="font-weight-black"
                        v-html="
                          selectedEvent.model !== 'order' &&
                          selectedEvent.model !== 'orderproduct'
                            ? selectedEvent.title
                            : ''
                        "
                      ></v-toolbar-title>
                      <v-col
                        cols="4"
                        v-if="
                          selectedEvent.model === 'order' ||
                          selectedEvent.model === 'orderproduct'
                        "
                      >
                        <span class="font-weight-black">
                          {{ `${$tc('code', 1)} ` }}
                        </span>
                        <span class="text subtitle-2">
                          {{ subOrder.code }}
                        </span>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="
                          selectedEvent.model === 'order' ||
                          selectedEvent.model === 'orderproduct'
                        "
                      >
                        <span class="text font-weight-black">
                          {{ `${$tc('product', 1)} ` }}
                        </span>
                        <span class="text subtitle-2">
                          {{ subOrder.own_prod }}
                        </span>
                      </v-col>
                      <v-spacer
                        v-if="
                          selectedEvent.model !== 'order' &&
                          selectedEvent.model !== 'orderproduct'
                        "
                      ></v-spacer>
                      <v-col cols="2" v-if="selectedEvent.model != 'order'">
                        <v-btn text @click="changeRoute(selectedEvent)">
                          <v-icon>fa-edit</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn text @click="selectedOpen = false">
                          <v-icon>fa-times</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-toolbar>
                    <v-card-title
                      primary-title
                      v-if="
                        selectedEvent.model === 'order' ||
                        selectedEvent.model === 'orderproduct'
                      "
                    >
                      <span v-if="subOrder.status == null">
                        {{ $t('editing', { value: $tc('status', 1) }) }}
                      </span>
                      <v-col cols="12">
                        <suborder
                          class="primary--text subtitle-2 front-weight-black"
                          :order="selectedEvent.order"
                          :subOrder.sync="selectedEvent.subOrder"
                          :calendar="true"
                          :miniSubOrder.sync="subOrder"
                          @done="getEvents"
                        />
                      </v-col>
                      <v-chip
                        v-if="
                          selectedEvent.labelName !== null &&
                          selectedEvent.label !== ''
                        "
                        :close="true"
                        @click:close="edit(selectedEvent, undefined, undefined)"
                      >
                        {{ selectedEvent.labelName }}
                      </v-chip>
                      <i-btn
                        v-if="
                          selectedEvent.labelName === null &&
                          subOrder.status !== null
                        "
                        :title="$tc('edit', 1)"
                        classes="mx-1"
                        small
                        outlined
                        class="mx-2 mb-1"
                        :color="'info'"
                        :icon="'mdi-pencil'"
                        @click="editLabel"
                      />
                      <v-col v-if="dialog && selectedEvent.labelName === null">
                        <diallabel
                          v-model="dialog2"
                          :to-edit="toEdit2"
                          @done="addLabel"
                        />
                        <ValidationProvider
                          vid="label"
                          :name="$tc('label', 1)"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            autocomplete="off"
                            outlined
                            clearable
                            v-model="label"
                            :error-messages="errors[0]"
                            :items="labels"
                            class="secondary--text"
                            :loading="isLoading"
                            item-text="name"
                            item-value="pk"
                            key="label-input"
                            append-icon="fa-search"
                            :prepend-inner-icon="'fa-plus'"
                            @click:prepend-inner="open()"
                            :label="$tc('label', 1)"
                            @change="edit(selectedEvent, undefined, label)"
                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                    </v-card-title>
                    <v-card-title
                      primary-title
                      v-if="
                        selectedEvent.model !== 'order' &&
                        selectedEvent.model !== 'orderproduct'
                      "
                    >
                      <span v-html="selectedEvent.details"></span>
                    </v-card-title>
                  </v-card>
                </v-menu>
              </v-sheet>
              <br /><br /><br />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="2" v-if="viewFilters">
        <i-btn
          :title="$t('close')"
          outlined
          classes="mx-1"
          icon="fa-chevron-right"
          color="error"
          @click="viewFilters = false"
        />
        <v-col cols="12" sm="3" xs="12">
          <v-switch
            v-model="allFilter"
            :label="$tc('all', false)"
            color="secondary"
            hide-details
          ></v-switch>
        </v-col>
        <v-col v-if="viewFilters && allFilter">
          <ValidationProvider
            vid="department"
            :name="$tc('department', 1)"
            rules=""
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              v-model="department"
              autocomplete="off"
              clearable
              multiple
              :error-messages="errors[0]"
              :items="department_list"
              class="secondary--text"
              item-text="name"
              item-value="pk"
              key="department-input"
              append-icon="fa-search"
              :label="$tc('department', 1)"
              @change="getEvents"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col v-if="viewFilters">
          <ValidationProvider
            vid="template"
            :name="$t('template')"
            rules=""
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              clearable
              multiple
              v-model="template"
              autocomplete="off"
              :error-messages="errors[0]"
              :items="templates"
              class="secondary--text"
              item-text="name"
              item-value="pk"
              key="template-input"
              append-icon="fa-search"
              :label="$t('template')"
              @change="getEvents"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col v-if="viewFilters && (template.length > 0 || allFilter)">
          <ValidationProvider
            vid="status"
            :name="$tc('status', 1)"
            rules=""
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              v-model="status"
              autocomplete="off"
              clearable
              multiple
              :error-messages="errors[0]"
              :items="status_list"
              class="secondary--text"
              item-text="name"
              item-value="pk"
              key="status-input"
              append-icon="fa-search"
              :label="$tc('status', 1)"
              @change="getEvents"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col v-if="viewFilters && (template.length > 0 || allFilter)">
          <ValidationProvider
            vid="typeDate"
            :name="$tc('date', 1)"
            rules=""
            v-slot="{ errors }"
          >
            <v-autocomplete
              outlined
              v-model="typeDate"
              autocomplete="off"
              clearable
              multiple
              :error-messages="errors[0]"
              :items="typeDate_list"
              class="secondary--text"
              item-text="name"
              item-value="pk"
              key="typeDate-input"
              append-icon="fa-search"
              :label="$tc('date', 1)"
              @change="getEvents"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col>
          <span class="font-weight-black">
            {{ `${$tc('label', 2)} ` }}
          </span>
          <br />
          <v-chip color="secondary" text-color="white" @click="getEvents">
            {{ $tc('all') }}
          </v-chip>
          <br />
          <v-chip
            :close="label.canDelete"
            color="secondary"
            v-for="label in labels"
            :key="'chips-' + label.pk"
            text-color="white"
            @click="filterLabel(label.pk)"
            @click:close="removeChip(label)"
          >
            {{ label.name }}
          </v-chip>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="css" scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  width: 100%;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
}
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
import suborder from '../sale/MiniSuborder.vue'
import diallabel from './dialLabel.vue'
export default {
  components: {
    diallabel,
    suborder
  },
  data() {
    return {
      value: '',
      dragEvent: null,
      loading: false,
      allFilter: true,
      template: '',
      typeDate: '',
      department: '',
      status: '',
      templates: [],
      typeDate_list: [],
      status_list: [],
      department_list: [],
      subOrder: {},
      events: [],
      res_events: [],
      filter_type: ['maintenance', 'expense', 'order', 'orderproduct'],
      type: 'month',
      types: [
        { label: this.$tc('month', 1), value: 'month' },
        { label: this.$tc('week', 1), value: 'week' },
        { label: `4 ${this.$tc('days', 1)}`, value: '4day' }
      ],
      params: {
        date__gte: this.$moment().startOf('month').format('YYYY-MM-DD'),
        date__lte: this.$moment().endOf('month').format('YYYY-MM-DD')
      },
      selectedOpen: false,
      viewFilters: false,
      selectedElement: null,
      selectedEvent: {},
      currentMonth: this.$moment().format('MMMM'),
      label: '',
      labels: [],
      toEdit: null,
      toEdit2: null,
      dialog: false,
      dialog2: false,
      isLoading: false,
      notificationsColor: {},
      eventForm: {
        pk: '',
        label: '',
        title: '',
        details: '',
        date: '',
        mode: '',
        background: '',
        color: '',
        code: '',
        subOrder: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      company: 'company/getCompanyData'
    }),
    positionX() {
      return window.screen.width / 2 - this.cardWidth / 2
    },
    positionY() {
      return window.screen.height / 2 - this.cardHeight / 2
    },
    cardWidth() {
      return 350
    },
    cardHeight() {
      return 350
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    /**
     * addLabel
     * Método para agregar nueva cuenta a la lista, si se proporciona un evento.
     *
     * @param {Object} evt - Evento que puede contener una nueva etiqueta
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    addLabel(evt) {
      this.labels.push(evt)
      this.label = evt.pk
    },
    openFilters() {
      this.viewFilters = true
    },
    startDrag({ event, timed }) {
      this.selectedEvent = event
      if (event && !timed) {
        this.dragEvent = event
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      } else {
        return
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent) {
        this.dragEvent.start = new Date(`${event.date}T00:00:00`).getTime()
        this.dragEvent.end = new Date(
          `${event.promise_date}T00:00:00`
        ).getTime()
        const newStartTime = mouse - this.dragTime
        this.dragEvent.start = newStartTime
        this.dragEvent.end = newStartTime
      } else {
        return
      }
    },
    endDrag(tms) {
      this.dragTime = null
      this.dragEvent = null
      if (!this.selectedOpen) {
        this.edit(this.selectedEvent, tms.date, null)
      }
    },
    cancelDrag() {
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15
      const roundDownTime = roundTo * 60 * 1000
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    getEventColor(event) {
      return event.statusColor != undefined
        ? event.statusColor
        : event.background
    },
    /**
     * getEvents
     * Método que consulta y filtra los eventos de gastos
     * mantenimiento de equipo y subOrdenes en el calendario
     * donde los filtros aplicados son:
     * Para los tres casos: rango de fechas
     * Para las subOrdenes: departamento de producción, plantilla
     * del producto, estado y tipos de fecha de la subOrden
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getEvents({ start, end }) {
      const min =
        start !== undefined
          ? new Date(`${start.date}T00:00:00`).getTime()
          : this.$moment().startOf('month').format('YYYY-MM-DD')
      const max =
        end !== undefined
          ? new Date(`${end.date}T00:00:00`).getTime()
          : this.$moment().startOf('month').format('YYYY-MM-DD')
      this.currentMonth =
        end !== undefined
          ? this.$moment(end.date).format('MMMM')
          : this.$moment().endOf('month').format('MMMM')
      const params = {
        params: {
          date__gte:
            start !== undefined
              ? start.date
              : this.$moment().startOf('month').format('YYYY-MM-DD'),
          date__lte:
            end !== undefined
              ? end.date
              : this.$moment().endOf('month').format('YYYY-MM-DD')
        }
      }
      const timed = false
      if (
        this.department.length == 0 &&
        this.template.length == 0 &&
        this.status.length == 0 &&
        this.typeDate.length == 0
      ) {
        this.$api.event
          .list({
            opt: {
              params: params
            }
          })
          .then((response) => {
            this.returnEvents(end, start, response, timed)
          })
          .finally(() => {
            if (this.$store.getters.getIsloading) {
              this.$store.dispatch('actionLoading')
            }
            this.loading = false
          })
      } else {
        this.$api.event
          .list({
            opt: {
              params: {
                params,
                department:
                  this.department.length > 0
                    ? this.department.toString()
                    : null,
                template:
                  this.template.length > 0 ? this.template.toString() : null,
                status: this.status.length > 0 ? this.status.toString() : null,
                typeDate:
                  this.typeDate.length > 0 ? this.typeDate.toString() : null
              }
            }
          })
          .then((response) => {
            this.returnEvents(end, start, response, timed)
          })
          .finally(() => {
            if (this.$store.getters.getIsloading) {
              this.$store.dispatch('actionLoading')
            }
            this.loading = false
          })
        if (this.template.length > 0 && !this.allFilter) {
          this.getStatus()
          this.getTypeDate()
        }
      }
    },
    returnEvents(end, start, response, timed) {
      this.events = response.data.map((event) => {
        return {
          ...event,
          name:
            (event.model === 'order' || event.model === 'orderproduct') &&
            event.code !== null &&
            event.template !== null
              ? event.title + ' -' + event.details
              : event.title,
          color:
            event.statusColor != undefined
              ? event.statusColor
              : event.background,
          start: new Date(`${event.date}T00:00:00`).getTime(),
          end: new Date(`${event.promise_date}T00:00:00`).getTime(),
          timed
        }
      })
      this.res_events = this.events
      this.filter()
    },
    async getTemplate() {
      let template = await this.$api.product.template.list({
        opt: {
          params: {
            order: true,
            company: this.company.pk
          }
        }
      })
      this.templates = template.data
    },
    async getStatus() {
      if (this.template.length === 0 || this.allFilter) {
        let status = await this.$api.sale.status.list({})
        this.status_list = status.data
      } else {
        let status = await this.$api.sale.status.show({
          pk: this.template,
          opt: {
            params: {
              template: true
            }
          }
        })
        this.status_list = status.data
      }
    },
    async getTypeDate() {
      if (this.template.length === 0 || this.allFilter) {
        let typeDate = await this.$api.dashboard.typedate.list({})
        this.typeDate_list = typeDate.data.results.filter(
          (event) => event.name !== 'promise'
        )
      } else {
        let typeDate = await this.$api.dashboard.typedate.show({
          pk: this.template,
          opt: {
            params: {
              template: true
            }
          }
        })
        this.typeDate_list = typeDate.data.filter(
          (event) => event.name !== 'promise'
        )
      }
    },
    async getDepartment() {
      let department = await this.$api.department.list({})
      this.department_list = department.data.results
    },
    async getLabels() {
      try {
        this.load = true
        let response = await this.$api.event.labels.list({})
        this.labels = response.data.results
      } finally {
        this.load = false
      }
    },
    filter() {
      let filter_type = this.filter_type.includes('order')
        ? this.filter_type
        : this.filter_type.filter((event) => event !== 'orderproduct')
      this.events = this.res_events.filter((event) => filter_type)
    },
    filterLabel(label) {
      this.events = this.res_events.filter((event) => event.label == label)
    },
    async edit(event, date, label) {
      if (
        this.selectedEvent.model === 'order' ||
        this.selectedEvent.model === 'orderproduct'
      ) {
        this.eventForm = this.selectedEvent
        this.eventForm.label =
          this.selectedEvent.label === null && label !== undefined
            ? label
            : this.selectedEvent.label !== null && label === undefined
            ? ''
            : this.selectedEvent.label
        if (date !== undefined) {
          if (date === this.eventForm.date) {
            this.selectedOpen = true
          } else {
            this.eventForm.date = date
          }
        }
        let response = await this.$api.event.edit({
          pk: event.pk,
          form: this.eventForm
        })
        this.selectedEvent = response.data
      } else {
        this.selectedOpen = true
      }
      this.getEvents(this.params)
      this.getLabels()
      this.dialog = false
    },
    editLabel() {
      this.dialog = true
    },
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    changeRoute(item) {
      this.$router.push({
        name:
          item.model === 'expense'
            ? 'expensesRecurring-pk'
            : item.model === 'maintenance'
            ? 'equipment-maintenance'
            : '',
        params: { pk: this.codification({ code: item.identifier }) }
      })
    },
    removeChip(item) {
      this.showConfirmation({
        show: true,
        model: this.$tc('label', 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('label', 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.event.labels.remove({ pk: item.pk })
      })
    },
    /**
     * getNotifications
     * Método que consulta y filtra los tipos de notificación:
     * gastos, mantenimiento de equipo y subOrdenes de venta,
     * para obtener el color configurado en notificaciones y
     * aplicarlo a los swicth del calendario
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getNotifications() {
      let notification = await this.$api.notifications.list({})
      this.notifications = notification.data
      this.notificationsColor = {
        expense: this.notifications.filter(
          (m) => m.content_type.model == 'expense'
        )[0].background,
        maintenance: this.notifications.filter(
          (m) => m.content_type.model == 'maintenance'
        )[0].background,
        orderproduct: this.notifications.filter(
          (m) => m.content_type.model == 'orderproduct'
        )[0].background
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$tc('label', 1)) {
          this.getLabels()
          this.deleting = false
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  mounted() {
    this.getEvents(this.params)
    this.getLabels()
    this.getTemplate()
    this.getStatus()
    this.getTypeDate()
    this.getDepartment()
    this.getNotifications()
  }
}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}
.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }
  &:hover::after {
    display: block;
  }
}
</style>

<template>
  <v-container fluid class="kanban">
    <div class="statusContainer">
      <getBodyEmail
        app_label="sales"
        model="orderproduct"
        :condition="
          (filter === 1 &&
            email['newStatusName'] === 'preprocess - sended_quote') ||
          filter > 1
        "
        :custumerNotification="email['customerNotification']"
        :stateMain="filter === 1 ? 'sended_quote' : 'in_process'"
        :stateID="email['status_id']"
        :template="filter === 1 ? null : template"
        :email_notification="email['email']"
        :subject="
          email['newStatusName'] === 'sended_quote'
            ? $t('orderupdate', {
                product: email['productName'],
                old: oldStatus,
                new: email['newStatusName']
              })
            : $t('quote')
        "
        :recipient_list="email['customerEmail']"
        ref="getbodyemail"
      />
      <div
        v-show="!loadStatus"
        :class="{
          'pa-1': true,
          statusCard: true,
          noProducts: status.products.length === 0
        }"
        v-for="(status, key) in statuses"
        :key="`status-${status.pk}`"
      >
        <p class="text-caption text-center font-weight-medium text-uppercase">
          {{ $tc(status.name.split(' - ')[0], 1) }} -
          {{ $tc(status.name.split(' - ')[1], 1) }}
        </p>
        <div
          v-dragula="status.products"
          class="grey lighten-5 productsContainer"
          drake="first"
          :data-status="status.pk"
        >
          <v-card
            v-for="product in status.products"
            :data-block-id="`item+-${product.pk}+-${key}`"
            :data-key="product.pk"
            class="py-1 mb-1 elevation-1"
            :style="{ ...stylesList[key].card }"
            :key="`product-${product.pk}`"
          >
            <v-row no-gutters>
              <v-col cols="12" :class="{ 'mb-2': expand[product.pk] }">
                <v-row no-gutters>
                  <v-col cols="5">
                    <span
                      class="text-caption"
                      :style="{ ...stylesList[key].customerTitle }"
                    >
                      {{ product.customerName }}
                    </span>
                  </v-col>
                  <v-col
                    cols="6"
                    class="font-weight-medium text-caption text-uppercase"
                  >
                    {{ product.projectName }}
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small icon @click="expanded(product.pk)">
                      <v-icon
                        v-text="
                          !expand[product.pk]
                            ? 'fa-chevron-down'
                            : 'fa-chevron-up'
                        "
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-expand-transition>
                <v-col
                  cols="6"
                  md="4"
                  v-show="
                    expand[product.pk] !== undefined && expand[product.pk]
                  "
                >
                  <i-image
                    class="product-image"
                    :style="{
                      width: '5em !important',
                      height: '4em !important'
                    }"
                    :value="product.thumbnail"
                    :preview="true"
                    :readonly="true"
                  />
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col
                  cols="6"
                  md="7"
                  v-show="
                    expand[product.pk] !== undefined && expand[product.pk]
                  "
                  class="pl-2 font-weight-medium text-caption"
                >
                  {{ product.productName }}
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-row
                  no-gutters
                  v-show="
                    expand[product.pk] !== undefined && expand[product.pk]
                  "
                >
                  <v-col
                    cols="10"
                    class="pl-2 py-0"
                    style="max-height: 4rem; overflow-y: auto"
                  >
                    <p
                      class="my-0 py-0 text-caption"
                      v-show="modeView === 1"
                      v-for="(date, index) in product.datesOrder"
                      :key="`order-dates-${product.pk}-${index}`"
                    >
                      <b>{{ $t(date.type) }}:</b>
                      {{ dateFormat(date.date) }}
                    </p>
                    <p
                      class="my-0 py-0 text-caption"
                      v-show="modeView === 2"
                      v-for="(assigned_user, index) in product.assigned_users"
                      :key="`assigned_user-view-${product.pk}-${index}`"
                    >
                      <b>
                        {{
                          assigned_user.mode == 'designer'
                            ? $t('designer')
                            : $tc('productions', 1)
                        }}:
                      </b>
                      {{ assigned_user.username }}
                    </p>
                    <p
                      v-show="
                        modeView === 2 && product.assigned_users.length === 0
                      "
                    >
                      {{ $t('no_addi_data') }}
                    </p>
                    <p
                      class="my-0 py-0 text-caption"
                      v-show="modeView === 1"
                      v-for="date in product.dates"
                      :key="`dates-${date.pk}`"
                    >
                      <b>{{ date.type }}:</b>
                      {{ dateFormat(date.date) }}
                      <v-chip x-small color="primary" v-if="date.changeDate">
                        {{ $tc('change', 1) }}
                      </v-chip>
                    </p>
                  </v-col>
                  <v-col cols="1" class="px-1 py-0" style="height: 4rem">
                    <v-btn
                      @click.prevent="modeView = 1"
                      text
                      x-small
                      :color="modeView === 1 ? status.color : 'grey'"
                    >
                      <v-icon> icon-calendar </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="filter != 1"
                      text
                      x-small
                      @click.prevent="modeView = 2"
                      :color="modeView === 2 ? status.color : 'grey'"
                    >
                      <v-icon>icon-team</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-row>
            <v-expand-transition>
              <v-row
                class="justify-end"
                v-show="expand[product.pk] !== undefined && expand[product.pk]"
                no-gutters
              >
                <v-col
                  class="justify-end font-weight-medium text-caption"
                  cols="12"
                  sm="12"
                  :style="{ ...stylesList[key].statusIndicate }"
                >
                </v-col>
                <v-col
                  class="justify-end font-weight-medium text-caption"
                  cols="12"
                  sm="12"
                  :style="{ ...stylesList[key].statusIndicate }"
                >
                </v-col>
                <v-col
                  class="justify-end font-weight-medium text-caption"
                  cols="12"
                  sm="10"
                  :style="{ ...stylesList[key].statusIndicate }"
                >
                  {{ product.status }}
                </v-col>
                <v-col cols="12" sm="2">
                  <v-tooltip up>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        :color="status.color"
                      >
                        <v-icon>icon-share</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import getBodyEmail from '../email/getBodyEmail.vue'
export default {
  components: {
    getBodyEmail
  },
  props: {
    preStatuses: {
      type: Array
    },
    filter: {
      type: Number
    },
    template: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      loadStatus: false,
      stylesList: [],
      statuses: [],
      expand: {},
      nav: 1,
      modeView: 1,
      newStatus: '',
      oldStatus: '',
      product: ''
    }
  },
  watch: {
    preStatuses: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.getOrderStatus()
        } else {
          this.statuses = []
        }
      }
    }
  },
  created() {
    const service = this.$dragula.$service
    service.eventBus.$on('drop', (block) => {
      this.product = Number(block.el.dataset.key)
      this.newStatus = Number(block.container.dataset.status)
      if (this.$refs['getbodyemail'] != undefined) {
        this.$refs['getbodyemail'].getBodyEmail()
        for (let status of this.statuses) {
          let product = status.products.find(
            (item) => item.code == this.email['code']
          )
          if (product != undefined) {
            this.oldStatus = status.name
          }
        }
      }
      this.form = { product: this.product }
      this.$api.dashboard.edit({ pk: this.newStatus, form: this.form })
    })
  },
  methods: {
    expanded(key) {
      this.$set(
        this.expand,
        key,
        this.expand[key] !== undefined ? !this.expand[key] : true
      )
    },
    generateStylesDict(colors) {
      return {
        card: {
          'border-left': `10px solid ${colors.color}`,
          'border-top': `1px solid ${colors.color}`,
          'border-right': `1px solid ${colors.color}`,
          'border-bottom': `1px solid ${colors.color}`
        },
        customerTitle: {
          color: colors.dark ? 'white' : 'black',
          'background-color': colors.color,
          padding: '6px 7px',
          'border-top-left-radius': '0px',
          'border-top-right-radius': '10px',
          'border-bottom-right-radius': '10px',
          'font-weight': '700'
        },
        statusIndicate: {
          color: colors.dark ? 'white' : 'black',
          'background-color': colors.color,
          padding: '3px 6px',
          'border-top-left-radius': '0px',
          'border-top-right-radius': '10px',
          'border-bottom-right-radius': '10px',
          'text-align': 'center'
        }
      }
    },
    /**
     * getOrderStatus
     * Método que consulta y filtra los estados de subOrden
     * según la parte del proceso en que se encuentra y
     * que no tienen el campo hidden activo
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus() {
      try {
        this.loadStatus = true
        let statuses = this.preStatuses.filter(
          (event) => event.root == this.filter
        )
        this.statuses = statuses.filter((event) => event.hidden == false)
        this.stylesList = this.statuses.map((item) =>
          this.generateStylesDict({
            color: item.color,
            dark: item.dark
          })
        )
      } finally {
        this.loadStatus = false
      }
    }
  },
  computed: {
    ...mapGetters({
      getTotalNotifications: 'notifications/getTotalNotifications'
    }),
    email() {
      let values = []
      let newStatus = this.statuses.find((item) =>
        item.pk == this.newStatus ? item : ''
      )
      if (newStatus != undefined) {
        values['status_id'] = newStatus.pk
        values['newStatusName'] = newStatus.name
        values['email'] = newStatus.email
        for (let status of this.statuses) {
          let product = status.products.find((item) => item.pk == this.product)
          if (product != undefined) {
            values['productName'] = product.productName
            values['customerEmail'] = product.customerEmail
            values['customerNotification'] = product.customerNotification
            values['code'] = product.code
          }
        }
      }
      return values
    }
  }
}
</script>
<style lang="scss" scoped>
.kanban {
  .image-print {
    height: 4rem;
    position: inherit;
  }

  .statusContainer {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 690px) {
      display: block;
    }
  }

  .productsContainer {
    min-height: 2.5rem;
    height: 15rem;
    overflow-y: auto;
  }

  .statusCard {
    position: relative;
    overflow: hidden;
    margin: 0em 0.2em 0.5em 0.2em;
    // box-shadow: 0.1em 0.1em 0.3em #ddd;

    @media (min-width: 320px) {
      -ms-flex: 0 0 94%;
      flex: 0 0 94%;
      width: 94%;
    }

    @media (min-width: 512px) {
      -ms-flex: 0 0 97%;
      flex: 0 0 97%;
      width: 97%;
    }

    @media (min-width: 720px) {
      -ms-flex: 0 0 47%;
      flex: 0 0 47%;
      width: 47%;
    }

    @media (min-width: 1024px) {
      -ms-flex: 0 0 31.5%;
      flex: 0 0 31.5%;
      width: 31.5%;
    }

    @media (min-width: 1400px) {
      -ms-flex: 0 0 23.7%;
      flex: 0 0 23.7%;
      width: 23.7%;
    }

    @media (min-width: 1680px) {
      -ms-flex: 0 0 19%;
      flex: 0 0 19%;
      width: 19%;
    }

    @media (min-width: 1920px) {
      -ms-flex: 0 0 15.8%;
      flex: 0 0 15.8%;
      width: 15.8%;
    }

    @media (min-width: 2560px) {
      -ms-flex: 0 0 13.6%;
      flex: 0 0 13.6%;
      width: 13.6%;
    }

    @media (min-width: 3200px) {
      -ms-flex: 0 0 12%;
      flex: 0 0 12%;
      width: 12%;
    }

    @media (min-width: 3840px) {
      -ms-flex: 0 0 10.7%;
      flex: 0 0 10.7%;
      width: 10.7%;
    }

    @media (min-width: 5120px) {
      -ms-flex: 0 0 8.8%;
      flex: 0 0 8.8%;
      width: 8.8%;
    }

    @media (min-width: 6400px) {
      -ms-flex: 0 0 6.9%;
      flex: 0 0 6.9%;
      width: 6.9%;
    }

    @media (min-width: 7680px) {
      -ms-flex: 0 0 5.69%;
      flex: 0 0 5.69%;
      width: 5.69%;
    }
  }

  .statusCard.noProducts {
    & > p {
      color: red;
    }

    @media (min-width: 720px) {
      -ms-flex: 0 0 15%;
      flex: 0 0 15%;
      width: 15%;
      max-height: 17.5em;
    }
    @media (min-width: 1024px) {
      -ms-flex: 0 0 11%;
      flex: 0 0 11%;
      width: 11%;
      max-height: 17.5em;
    }

    @media (min-width: 1400px) {
      -ms-flex: 0 0 8%;
      flex: 0 0 8%;
      width: 8%;
      max-height: 17.5em;
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
</style>

}
